@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

.page-title{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}

.bottom-navigation{
    z-index: 30;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0px;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.bottom-navigation-frame{
    display: flex;
    background-color: white;
    margin-bottom: 16px;
    border-radius: 64px;
    width: 280px;
    height: 64px;
    align-items: center;
    justify-content: space-evenly;
    padding-left: 24px;
    padding-right: 24px;
}

.content{
    /* display: contents; */
    display:block
}

.max-width-adapt{
    width: 100%;
    max-width: 800px;
}

@media screen and (max-width: 800px) {
    .max-width-adapt{
        max-width: 400px;
    }
}

.below-top-bar{
    margin-top: 48px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
}

.center-div{
    display: flex;
    justify-content: center;
}

.notification-layout{
    animation: fadeIn 250ms;
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(4px);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 45;
    top: 0;
}

.notification-group-backdrop{
    position: absolute;
    top: 0;
    background-color: white;
    border-radius: 1em;
    color: black;
    width: 300px;
    margin-top: 8px;
    right: 0;
    padding: 1em;
    box-sizing: border-box;
}

.relative{
    position: relative;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

