.home-parent {
    display: grid;
    grid-gap: 16px;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 800px) {
    .home-parent {
        grid-template-columns: 100%;
    }
}

.loading-bg {
    z-index: 100;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(2px);
    top: 0;
    left: 0
}

.emergencyCardOverview-layout {
    animation: fadeIn 250ms;
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(4px)
}