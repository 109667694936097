@media screen and (min-width: 800px) {
    .dragAndDrop-container{
        width:760px;
    }


}

@media screen and (max-width: 800px) {
    .dragAndDrop-container{
        width:360px;
    }
    
}