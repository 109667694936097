@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");

.page-navigation-parent {
    background-color: white;
    color: #00000080;
    border-radius: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #00000040;

    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.btn-frame {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}
.btn-frame:hover {
    background: #ee4139;
    box-shadow: 0 0 8px 4px #ee413940;
    color: white !important;
}

.end-of-page {
    color: white !important;
}
.end-of-page:hover {
    background: #ee413980;
}

.first-btn {
    border-radius: 32px 4px 4px 32px;
}

.last-btn {
    border-radius: 4px 32px 32px 4px;
}

.page-navigation-button {
    border: none;
    background: none;
    padding: 5;
}

.btn-text {
    font-family: "Lato" sans-serif;
    font-weight: 500;
}
.btn-text:hover {
    color: black;
}

.current-page {
    color: #ee4139;
}
