@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.version{
  align-items: flex-end;
    bottom: 0.5em;
    display: flex;
    height: 100vh;
    justify-content: flex-end;
    opacity: .25;
    pointer-events: none;
    position: fixed;
    right: 0.5em;
    width: 100vw;
    color:#C4C4C4;
}

.css-grid{
   display: flex;           
  align-items: center;     
  justify-content: center;  
}
.hide-native-scrollbar {
  scrollbar-width: none;
  /* Firefox 64 */
  -ms-overflow-style: none;
  /* Internet Explorer 11 */
}

.hide-native-scrollbar::-webkit-scrollbar {
  /** WebKit */
  display: none;
}

body {
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden
}

.standard-button {
  align-items: center;
  background-clip: padding-box;
  background-color: #fa6400;
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(0.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.standard-button:hover,
.standard-button:focus {
  background-color: #fb8332;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.standard-button:hover {
  transform: translateY(-1px);
}

.standard-button:active {
  background-color: #c85000;
  box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
  transform: translateY(0);
}

.defaultPage-bg {

  overflow-x: hidden;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(/static/media/DefaultPageBackground.eed98576.svg);
  background-position: center;
}

.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  position: 'absolute';
  height: 40;
  left: 0;
  top: WINDOW_HEIGHT - 40;
  width: WINDOW_WIDTH;
}

.backdrop {
  background: #FFFFFF;
  border-radius: 1em;
  box-shadow: 16px, 16px, 8px, red;
}

.layout {
  --linear-layout-item-gap: 5px;
}

.sub-layout>* {
  --linear-layout-item-gap: 20px;
}

.input-text {
  color: black !important;
  border-color: yellowgreen !important;
}

.input-text::before {
  color: black !important;
  border-color: yellowgreen !important;
  border-bottom: 1px solid grey !important;

}

.input-text::after {
  color: black !important;
  border-color: yellowgreen !important;
  border-bottom: 2px solid #114B7A !important;

}

.input-text-container {
  color: #F5F6FA !important;
  border-color: yellowgreen !important;
  background-color: #F5F6FA;
  border-radius: 10px;
}

.input-text-label {
  color: #C4C4C4 !important;
  font-family: "Lato";
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.roll-out {
  @keyframes rollout {
    0% {
      transform: translateX(0px);
    }

    100% {
      transform: translateX(25px);
    }
  }

  animation: rollout 0.5s;
}

.roll-out-mobile {
  @keyframes rolloutmobile {
    0% {
      transform: translateX(0px);
    }

    100% {
      transform: translateX(15px);
    }
  }

  animation: rolloutmobile 0.5s;
}

.roll-in {
  @keyframes rollin {
    0% {
      transform: translateX(25px);
    }

    100% {
      transform: translateX(0px);
    }
  }

  animation: rollin 0.5s;
}

.roll-in-mobile {
  @keyframes rollinmobile {
    0% {
      transform: translateX(15px);
    }

    100% {
      transform: translateX(0px);
    }
  }

  animation: rollinmobile 0.5s;
}

.fade-in-element-a {
  @keyframes fade-in-a {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  animation: fade-in-a 0.5s;
}

.fade-in-element-b {
  @keyframes fade-in-b {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  animation: fade-in-b 0.5s;
}

.fade-in-element-c {
  @keyframes fade-in-c {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  animation: fade-in-c 0.5s;
}

.fade-in-element-d {
  @keyframes fade-in-d {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  animation: fade-in-d 0.5s;
}

.fade-in-element-e {
  @keyframes fade-in-e {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  animation: fade-in-e 0.5s;
}

.ripple-wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
  pointer-events: none;
}

.ripple-emitter {
  position: relative;
  margin: 0 auto;
  border-radius: 9999px;
  background-image: linear-gradient(to right, white 0%, #fee140 100%);
}

.ripple-wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  background-image: linear-gradient(to right, white 0%, #fee140 100%);
  opacity: 0;
  z-index: -1;
  pointer-events: none;
}

.minimize-dashboard {
  max-width: 1000px;
  animation: minimizeDashboard 20.5s
}

.maximize-dashboard {
  animation: minimizeDashboard 0.5s reverse
}

.dashboard-container {
  width: 100vw;
  display: flex;
  justify-content: center;
}



#custom-scroll::-webkit-scrollbar {
  width: 0px;
  background-color: #F5F5F520;
}

#custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 2px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #FFDACC;
}

.voucher-bar-offer {
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-self: center;
  justify-content: center;
}

.ripple-wave {
  animation: rippleWave 4s ease-in infinite;
}


.ripple-float {
  animation: rippleFloat 4s ease-in infinite;
}

@keyframes rippleWaveBG {
  0% {
    opacity: 0;
    transform: scale(0.075) translateY(20vh);
    filter: blur(0.25em);
  }

  50% {
    opacity: 0.125;
    filter: blur(1em)
  }

  100% {
    transform: scale(1) translateY(-10vh);
    opacity: 0;
    filter: blur(0.5em);
  }
}

@keyframes rippleWaveMG {
  0% {
    opacity: 0;
    transform: scale(0.075) translateY(20vh);
    filter: blur(0.05em);
  }

  50% {
    opacity: 0.125;
    filter: blur(0.2em)
  }

  100% {
    transform: scale(1) translateY(-40vh);
    opacity: 0;
    filter: blur(0.1em);
  }
}

@keyframes rippleWaveFG {
  0% {
    opacity: 0;
    transform: scale(0.075) translateY(20vh);
  }

  50% {
    opacity: 0.125;
  }

  100% {
    transform: scale(1) translateY(-80vh);
    opacity: 0;
  }
}

@keyframes rippleFloat {
  0% {
    transform: translateY(-20vh);
  }

  100% {
    transform: translateY(-60vh);
  }
}

@keyframes minimizeDashboard {
  0% {
    width: 100vw;
  }

  100% {
    width: 900px;
  }
}

@keyframes maximizeDashboard {
  0% {
    width: 900px;
  }

  100% {
    width: 100vw;
  }
}

@keyframes minimize-info-text {
  0% {
    font-size: 28px;
  }

  100% {
    font-size: 20px;
  }
}


@keyframes maximize-info-text {
  0% {
    font-size: 20px;
  }

  100% {
    font-size: 28px;
  }
}
.home-parent {
    display: grid;
    grid-gap: 16px;
    justify-content: center;
    padding-bottom: 128px;
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 800px) {
    .home-parent {
        grid-template-columns: 100%;
    }
}

.emergencyCardOverview-layout {
    animation: fadeIn 250ms;
    background: rgba(0, 0, 0, 0.75);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px)
}

.max-width-adapt {
    width: 100%;
    /* max-width: 800px; */
}

.center-div {
    display: flex;
    justify-content: center;

}

.below-top-bar {
    margin-top: 48px;

}

.loading-bg {
    z-index: 100;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.75);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    top: 0;
    left: 0
}
.dialog-dual-button{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    grid-gap: 0.5em;
    gap: 0.5em;
    padding: 1em;
}
.card-frame-parent {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(255, 255, 255, 0.25);
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-sizing: border-box;
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.25);
    border-radius: 16px;
    width: 100%;
    padding: 16px;
}

.card-frame-title{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: white;
}

.card-frame-content{
    margin-top: 16px;
}

.large-card{
    width: 700px !important;
}
.home-parent {
    display: grid;
    grid-gap: 16px;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 800px) {
    .home-parent {
        grid-template-columns: 100%;
    }
}

.loading-bg {
    z-index: 100;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.75);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    top: 0;
    left: 0
}

.emergencyCardOverview-layout {
    animation: fadeIn 250ms;
    background: rgba(0, 0, 0, 0.75);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px)
}
.page-navigation-parent {
    background-color: white;
    color: #00000080;
    border-radius: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #00000040;

    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.btn-frame {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}
.btn-frame:hover {
    background: #ee4139;
    box-shadow: 0 0 8px 4px #ee413940;
    color: white !important;
}

.end-of-page {
    color: white !important;
}
.end-of-page:hover {
    background: #ee413980;
}

.first-btn {
    border-radius: 32px 4px 4px 32px;
}

.last-btn {
    border-radius: 4px 32px 32px 4px;
}

.page-navigation-button {
    border: none;
    background: none;
    padding: 5;
}

.btn-text {
    font-family: "Lato" sans-serif;
    font-weight: 500;
}
.btn-text:hover {
    color: black;
}

.current-page {
    color: #ee4139;
}

.page-title{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}

.bottom-navigation{
    z-index: 30;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0px;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.bottom-navigation-frame{
    display: flex;
    background-color: white;
    margin-bottom: 16px;
    border-radius: 64px;
    width: 280px;
    height: 64px;
    align-items: center;
    justify-content: space-evenly;
    padding-left: 24px;
    padding-right: 24px;
}

.content{
    /* display: contents; */
    display:block
}

.max-width-adapt{
    width: 100%;
    max-width: 800px;
}

@media screen and (max-width: 800px) {
    .max-width-adapt{
        max-width: 400px;
    }
}

.below-top-bar{
    margin-top: 48px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
}

.center-div{
    display: flex;
    justify-content: center;
}

.notification-layout{
    animation: fadeIn 250ms;
    background: rgba(0, 0, 0, 0.75);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 45;
    top: 0;
}

.notification-group-backdrop{
    position: absolute;
    top: 0;
    background-color: white;
    border-radius: 1em;
    color: black;
    width: 300px;
    margin-top: 8px;
    right: 0;
    padding: 1em;
    box-sizing: border-box;
}

.relative{
    position: relative;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}


.bottom-navigation-btn{
    padding: 12px;
    height: 28px;
    width: 28px;
    object-fit: cover;
    border-radius: 28px;
}

.bottom-navigation-btn:hover{
    background-color: #EE413920;
}
.feed-card-overlay {
    background: linear-gradient(180deg, rgba(31, 31, 31, 0) 42.19%, rgba(31, 31, 31, 0.916667) 80.94%, #1F1F1F 100%);
    border: 2px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    z-index: 1;
    position: absolute;
}

.feed-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 0;
    border-radius: 8px;
    padding: 2px;
    box-sizing: border-box;
}

.feed-card {
    position: relative;
    height: 200px;
    width: 100%;
}

.feed-card-edit-button {
    background-color: white;
    border: 1px solid #EE4139;
    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25);
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    border-radius: 8px;
    padding: 4px;
    cursor: pointer;
    z-index: 10;
}

.feed-card-info {
    font-family: 'Lato', sans-serif;
    color: white;
    position: absolute;
    bottom: 0px;
    z-index: 10;
    padding: 12px;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}

.feed-card-datettime {
    font-size: 12px;
}

.feed-card-title {
    width: 100%;
    font-size: 18px;
    margin-top: 4px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
}

.feed-to-upload-image{
    object-fit: contain;
    border-radius: 8px;
    padding: 5px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}
@media screen and (min-width: 800px) {
    .dragAndDrop-container{
        width:760px;
    }


}

@media screen and (max-width: 800px) {
    .dragAndDrop-container{
        width:360px;
    }
    
}
@media screen and (min-width: 800px) {
    .feed-container {
        width: 740px;
    }

    .publish {
        width: 740px;
    }

    .save-content-button {
        width: 760px;
    }

    .dropdown {
        width: 750px;
    }

}

@media screen and (max-width: 800px) {
    .feed-container {
        width: 340px;
    }

    .publish {
        width: 340px;
    }

    .save-content-button {
        width: 360px;
    }

    .dropdown {
        width: 350px;
    }


}
.page-navigation-parent {
    background-color: white;
    color: #00000080;
    border-radius: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #00000040;

    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.btn-frame {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}
.btn-frame:hover {
    background: #ee4139;
    box-shadow: 0 0 8px 4px #ee413940;
    color: white !important;
}

.end-of-page {
    color: white !important;
}
.end-of-page:hover {
    background: #ee413980;
}

.first-btn {
    border-radius: 32px 4px 4px 32px;
}

.last-btn {
    border-radius: 4px 32px 32px 4px;
}

.page-navigation-button {
    border: none;
    background: none;
    padding: 5;
}

.btn-text {
    font-family: "Lato" sans-serif;
    font-weight: 500;
}
.btn-text:hover {
    color: black;
}

.current-page {
    color: #ee4139;
}

.more-btn{
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: -webkit-min-content;
    width: min-content;
    padding: 8px;
    margin-top: 20px;
    cursor: pointer;
}

.more-btn:hover{
    background: rgba(255, 255, 255, 0.35);
}
