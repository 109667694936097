.bottom-navigation-btn{
    padding: 12px;
    height: 28px;
    width: 28px;
    object-fit: cover;
    border-radius: 28px;
}

.bottom-navigation-btn:hover{
    background-color: #EE413920;
}