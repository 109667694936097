.card-frame-parent {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(255, 255, 255, 0.25);
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-sizing: border-box;
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.25);
    border-radius: 16px;
    width: 100%;
    padding: 16px;
}

.card-frame-title{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: white;
}

.card-frame-content{
    margin-top: 16px;
}

.large-card{
    width: 700px !important;
}